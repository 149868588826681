import React from 'react';
import { useNavigate } from 'react-router-dom';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';

import SettingsIcon from '../icons/hero/Settings.png';

const SettingsHeroIcon = (props) =>
{
	const navigate = useNavigate();

	const audiomessage = "Tools/Settings/Press"; 
	const hoveraudiomessage = "Tools/Settings/HL";

	const onClickSettings = (toolbar) =>
	{
		if (toolbar)
		{
			postVuplexMessage('Request settings view', null);
		}
		else
		{
			navigate('/view/settings/general');
		}
	};

	return (
		<HeroIcon
			name="Settings"
			noBackground={props.noBackground}
			icon={SettingsIcon}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
			onPointerDown={() => onClickSettings(props.isToolbar)}
			hotkey="4"
		/>
	);
};

export default SettingsHeroIcon;
