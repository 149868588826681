import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import queries from '../graphql/queries';

import Tablet_OPEN_Wrist from '../icons/Tablet_OPEN_Wrist.png';
import Menu_CLOSE from '../icons/Menu_CLOSE.png';

const TabletHeroIcon = (props) =>
{
	const openTablet = () => postVuplexMessage('Open tablet', null);
	const closeTablet = () => postVuplexMessage('Close tablet', null);

	const isToolbar = props.isToolbar;

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	const vr = clientPlatform?.PlatformType === "VR";

	const toggleOffSound = "Tools/Tablet/CloseTablet/Press";
	const toggleOnSound = "Tools/Tablet/OpenTablet/Press";
	const audiomessage = isToolbar ? toggleOnSound :  toggleOffSound; 
	const hoveraudiomessage = "Tools/Tablet/HL";

	return (
		<HeroIcon
			name={isToolbar ? "Open Tablet" : "Close Tablet"}
			noBackground={props.noBackground}
			icon={isToolbar ? Tablet_OPEN_Wrist : Menu_CLOSE}
			onPointerDown={isToolbar ? openTablet : closeTablet}
			disabled={isToolbar ? vr : false}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
			hotkey="Tab"
			hotkeyCenter
		/>
	);
};

export default TabletHeroIcon;
