import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AvatarCanvas from './avatarconfigurator/avatarcanvas';
import { useUserSettings } from '../service/user-settings';
import SDKPromoImage from '../images/WhatsNew_HandUI.jpg';
import { useUserContext } from '../util/user-context';

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'grid',
		gridTemplateColumns: '1fr 1fr',
		gap: theme.glueSpacing('m'),
		padding: `64px 43px ${theme.glueSpacing('xl')} 43px`,
		overflow: 'hidden',
		...theme.custom.homePage.root,

		'@media (max-width:1280px)': {
			gridTemplateColumns: 'unset !important',
		},
	},

	contentBox: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('m')
	},

	loggedInAs: {
		display: 'flex', 
		flexDirection: 'row',
		gap: theme.glueSpacing('s'),
		bottom: theme.glueSpacing('xl'),

		'& p:first-child': {
			opacity: '60%'
		}
	},

	pictureDisplay: {
		overflow: 'hidden'
	},

	whatsNew: {
		display: 'flex',
		flexFlow: 'column',
		gap: theme.glueSpacing('l'),
		overflow: 'hidden',
	},

	card: {
		borderRadius: '4px',
		background: theme.palette.background.paper,
		overflow: 'hidden'
	},

	promoImage: {
		borderRadius: '4px',
		objectFit: 'cover',
		...theme.custom.homePage.promoImage
	},

	cardContent: {
		padding: `${theme.glueSpacing('m')} ${theme.glueSpacing('l')} ${theme.glueSpacing('l')} ${theme.glueSpacing('l')}`,

		'& .MuiTypography-root': {
			marginBottom: theme.glueSpacing('s')
		},

		'& h2': {
			marginBottom: theme.glueSpacing('m')
		},

		'& p': {
			marginBottom: theme.glueSpacing('l')
		},
	}
}), { name: 'MuiGlueHomePage' });

const HomePage = (props) =>
{
	const classes = useStyles();
	const { userSettings } = useUserSettings();
	const user = useUserContext();

	const username = user.email;
	const nickname = userSettings?.nickname ? userSettings?.nickname : username;

	if (!userSettings?.avatar)
	{
		console.warn("Avatar config data missing");
	}

	return(
		<div className={classes.root}>
			<div className={classes.contentBox}>
				<h2>{`Hi, ${nickname}!`}</h2>
				<div className={classes.loggedInAs}>
					<p>{'You are logged in as:'}</p>
					<p>{username}</p>
				</div>
				<div className={classes.pictureDisplay}>
					{userSettings?.avatar && (
						<AvatarCanvas
							avatar={{ avatar: userSettings?.avatar }}
							width={620}
							height={620}
							id={"mainAvatarView"}
							targetZoomLevel={1}
							targetHeight={1.5}
						/>
					)}
				</div>
			</div>
			<div className={classes.whatsNew}>
				<h2>What&apos;s new in Glue?</h2>
				<div className={classes.card}>
					<img src={SDKPromoImage} alt='' className={classes.promoImage}></img>
					<div className={classes.cardContent}>
						<Typography variant='overline'>Release 2023-12</Typography>
						<h2>Glue&apos;s handy new feature - HandUI</h2>
						<p>HandUI brings VR users immediate access to the most relevant status information.</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomePage;
