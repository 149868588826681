import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import queries from '../graphql/queries';
import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import MicActivityBar from './mic-activity-bar';

import MicOnIcon from '../icons/hero/Mic_ON.png';
import MicOffIcon from '../icons/hero/Mic_OFF.png';

const toggleMic = () =>
{
	postVuplexMessage('Toggle microphone', null);
};

const MicHeroIcon = (props) =>
{
	const { data } = useQuery(queries.microphoneEnabled);

	const toggleOffSound = "Tools/Microphone/Toggle/Off/Press";
	const toggleOnSound = "Tools/Microphone/Toggle/On/Press";
	const audiomessage = data.microphoneEnabled ? toggleOffSound :  toggleOnSound; 
	const hoveraudiomessage = "Tools/Microphone/HL";

	if (!data)
		return null;

	useEffect(() => {
		postVuplexMessage("Microphone.SetUpdatesActive", { value: true });
		return () => {
			postVuplexMessage("Microphone.SetUpdatesActive", { value: false });
		}
	});

	return (
		<HeroIcon
			name={data.microphoneEnabled ? "Mic is ON" : "Mic is OFF"}
			noBackground={props.noBackground}
			icon={MicOnIcon}
			iconOff={MicOffIcon}
			onPointerDown={toggleMic}
			toggled={data.microphoneEnabled}
			uiAudioMessage={audiomessage}
			uiHoverAudioMessage={hoveraudiomessage}
			bottom={<MicActivityBar bgColor={'black'} widthSize={'80px'}/>}
			hotkey="2"
		/>
	);
};

export default MicHeroIcon;
