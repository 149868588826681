import { createTheme } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { PaletteOptions, SimplePaletteColorOptions } from '@material-ui/core/styles/createPalette';
import { Overrides } from '@material-ui/core/styles/overrides';

import { tablet as GlueButtonTablet, web as GlueButtonWeb, Type as GlueButtonType } from '../theme/glue-button';
import { tablet as GlueToggleTablet, web as GlueToggleWeb, Type as GlueToggleType } from '../theme/glue-toggle';
import { tablet as GlueRadioTablet, web as GlueRadioWeb, Type as GlueRadioType } from '../theme/glue-radio';
import { tablet as GlueAccordionTablet, web as GlueAccordionWeb, Type as GlueAccordionType } from '../theme/glue-accordion';
import { tablet as GlueEditorTablet, web as GlueEditorWeb, Type as GlueEditorType } from '../theme/glue-editor';
import { tablet as EditorPanelTablet, web as EditorPanelWeb, Type as EditorPanelType } from '../theme/editor-panel';
import { tablet as PageSelectorTablet, web as PageSelectorWeb, Type as PageSelectorType } from '../theme/page-selector';
import { tablet as GlueTabTablet, web as GlueTabWeb, Type as GlueTabType } from '../theme/glue-tab';
import { tablet as HeaderTablet, web as HeaderWeb, Type as HeaderType } from '../theme/header';
import { tablet as GlueScrollTablet, web as GlueScrollWeb, Type as GlueScrollType } from '../theme/glue-scroll';
import { tablet as GlueDropdownTablet, web as GlueDropdownWeb, Type as GlueDropdownType } from '../theme/glue-dropdown';
import { tablet as GlueListItemTablet, web as GlueListItemWeb, Type as GlueListItemType } from '../theme/glue-list-item';
import { tablet as ViewTablet, web as ViewWeb, Type as ViewType } from '../theme/view';
import { tablet as IconTablet, web as IconWeb, Type as IconType } from '../theme/icon';
import { tablet as LoadingIndicatorTablet, web as LoadingIndicatorWeb, Type as LoadingIndicatorType } from '../theme/loading-indicator';
import { tablet as GlueCardTablet, web as GlueCardWeb, Type as GlueCardType } from '../theme/glue-card';
import { tablet as SpacesTablet, web as SpacesWeb, Type as SpacesType } from '../theme/spaces';
import { tablet as FilesTablet, web as FilesWeb, Type as FilesType } from '../theme/files';
import { tablet as HomePageTablet, web as HomePageWeb, Type as HomePageType } from '../theme/home-page';
import { tablet as ImgPreviewTablet, web as ImgPreviewWeb, Type as ImgPreviewType } from '../theme/image-preview-component';
import { tablet as SelectOutlineTablet, web as SelectOutlineWeb, Type as SelectOutlineType } from '../theme/select-outline';
import { tablet as HoverOverlayTablet, web as HoverOverlayWeb, Type as HoverOverlayType } from '../theme/hover-overlay';
import { tablet as TagTablet, web as TagWeb, Type as TagType } from '../theme/glue-tag';
import { tablet as GotoSpaceTablet, web as GotoSpaceWeb, Type as GotoSpaceType } from '../theme/goto-space-dialog';
import { tablet as SpaceTemplatesDialogTablet, web as SpaceTemplatesDialogWeb, Type as SpaceTemplatesDialogType } from '../theme/space-templates-dialog';
import { tablet as ClockTablet, web as ClockWeb, Type as ClockType } from '../theme/clock';
import { tablet as SelfButtonTablet, web as SelfButtonWeb, Type as SelfButtonType } from '../theme/self-button';
import { tablet as TeamSelectorTablet, web as TeamSelectorWeb, Type as TeamSelectorType } from '../theme/team-selector';
import { tablet as OrgIconTablet, web as OrgIconWeb, Type as OrgIconType } from '../theme/org-icon';
import { tablet as OrgAccordionTablet, web as OrgAccordionWeb, Type as OrgAccordionType } from '../theme/org-accordion';
import { tablet as ManageTeamMembersTablet, web as ManageTeamMembersWeb, Type as ManageTeamMembersType } from '../theme/team-members';
import { tablet as TeamMemberDetailsTablet, web as TeamMemberDetailsWeb, Type as TeamMemberDetailsType } from '../theme/team-member-details';
import { tablet as InfoDialogTablet, web as InfoDialogWeb, Type as InfoDialogType } from '../theme/info-dialog';
import { tablet as GeneralDialogTablet, web as GeneralDialogWeb, Type as GeneralDialogType } from '../theme/general-dialog-template';
import { tablet as ListViewTablet, web as ListViewWeb, Type as ListViewType } from '../theme/glue-list-view';
import { tablet as CreateTeamDialogTablet, web as CreateTeamDialogWeb, Type as CreateTeamDialogType } from '../theme/create-team-dialog';

import { tablet as AvatarEditorTablet, web as AvatarEditorWeb, Type as AvatarEditorType } from '../theme/avatar-editor';
import { tablet as ColorPickerTablet, web as ColorPickerWeb, Type as ColorPickerType } from '../theme/color-picker';

import { tablet as ContentBaseTablet, web as ContentBaseWeb, Type as ContentBaseType } from '../theme/content-base';

import SourceSansProSemiBoldFont from '../font/SourceSansPro-SemiBold.ttf';
import SourceSansProBoldFont from '../font/SourceSansPro-Bold.ttf';
import SourceSansProRegularFont from '../font/SourceSansPro-Regular.ttf';

import SourceSerifProRegularFont from '../font/SourceSerifPro-Regular.otf'
import SourceSerifProBoldFont from '../font/SourceSerifPro-Bold.otf'

import SrirachaRegularFont from '../font/Sriracha-Regular.ttf'

const sourceSansPro_Regular = {
	fontFamily: 'Source Sans Pro',
	fontStyle: 'normal',
	fontDisplay: 'swap' as const, // Prevent type widening...
	fontWeight: 400,
	src: `url(${SourceSansProRegularFont}) format('truetype')`
};

const sourceSansPro_SemiBold = {
	fontFamily: 'Source Sans Pro',
	fontStyle: 'normal',
	fontDisplay: 'swap' as const,
	fontWeight: 600,
	src: `url(${SourceSansProSemiBoldFont}) format('truetype')`
};

const sourceSansPro_Bold = {
	fontFamily: 'Source Sans Pro',
	fontStyle: 'normal',
	fontDisplay: 'swap' as const,
	fontWeight: 700,
	src: `url(${SourceSansProBoldFont}) format('truetype')`
};

const sourceSerifPro_Regular = {
	fontFamily: 'Source Serif Pro',
	fontStyle: 'normal',
	fontDisplay: 'swap' as const,
	fontWeight: 400,
	src: `url(${SourceSerifProRegularFont}) format('opentype')`
};

const sourceSerifPro_Bold = {
	fontFamily: 'Source Serif Pro',
	fontStyle: 'normal',
	fontDisplay: 'swap' as const,
	fontWeight: 700,
	src: `url(${SourceSerifProBoldFont}) format('opentype')`
};

const sriracha_Regular = {
	fontFamily: 'Sriracha',
	fontStyle: 'normal',
	fontDisplay: 'swap' as const,
	fontWeight: 400,
	src: `url(${SrirachaRegularFont}) format('truetype')`
};

const paletteCommon = {
	// Greys
	white: '#fff',
	grey50: '#f7f9fa',
	grey100: '#ebeef0',
	grey200: '#d5dade',
	grey300: '#c2c6cc',
	grey400: '#a9adb3',
	grey500: '#969a9e',
	grey600: '#83898f',
	grey700: '#63686e',
	grey800: '#454b52',
	grey900: '#363a40',
	grey950: '#252a2e',
	black: '#000',

	// Blues
	blue50: '#ebf5ff',
	blue100: '#d1e8ff',
	blue200: '#abd5ff',
	blue300: '#7abdff',
	blue400: '#45a2ff',
	blue500: '#1887f5',
	blue600: '#0073e6',
	blue700: '#005cb8',
	blue800: '#00468c',
	blue900: '#003162',
	blue950: '#001b36',

	// Purples
	purple50: '#f0e8ff',
	purple100: '#e0d1ff',
	purple200: '#d2bbff',
	purple300: '#c4a5ff',
	purple400: '#b38cff',
	purple500: '#a375ff',
	purple600: '#8a62da',
	purple700: '#6b4aac',
	purple800: '#513786',
	purple900: '#332059',
	purple950: '#1a0d34',

	// Reds
	red50: '#fff5f6',
	red100: '#ffe6e8',
	red200: '#ffbfc5',
	red300: '#ff8c96',
	red400: '#eb636a',
	red500: '#d9363b',
	red600: '#b3242d',
	red700: '#8c131f',
	red800: '#6e101a',
	red900: '#4a0c11',
	red950: '#33080c',
	
	// Greens
	green50: '#e0ffed',
	green100: '#b9f0cf',
	green200: '#8ee0af',
	green300: '#6ad193',
	green400: '#41c274',
	green500: '#1cb458',
	green600: '#169646',
	green700: '#127d36',
	green800: '#0c5f23',
	green900: '#084513',
	green950: '#032d04',
	
	// Yellows
	yellow50: '#fff8e6',
	yellow100: '#fff1c9',
	yellow200: '#ffe7a6',
	yellow300: '#ffdd80',
	yellow400: '#f7cd56',
	yellow500: '#e6b837',
	yellow600: '#c7921f',
	yellow700: '#a86e09',
	yellow800: '#764904',
	yellow900: '#482700',
	yellow950: '#271504',
	
	// Oranges
	orange50: '#ffe3d9',
	orange100: '#fac6af',
	orange200: '#f5ab88',
	orange300: '#f08b5a',
	orange400: '#f07031',
	orange500: '#e05916',
	orange600: '#c04709',
	orange700: '#993907',
	orange800: '#762d06',
	orange900: '#4d1e03',
	orange950: '#271102',
	
	success: '#00ff84',
	alert: '#fcc21f',
	error: '#e61717',
}

type CustomThemeProps = {
	contentBase: ContentBaseType
	glueButton: GlueButtonType
	glueToggle: GlueToggleType
	glueRadio: GlueRadioType
	glueAccordion: GlueAccordionType
	glueEditor: GlueEditorType
	editorPanel: EditorPanelType
	colorPicker: ColorPickerType
	pageSelector: PageSelectorType
	glueTab: GlueTabType
	header: HeaderType
	glueScroll: GlueScrollType
	glueDropdown: GlueDropdownType
	glueListItem: GlueListItemType
	view: ViewType
	icon: IconType
	loadingIndicator: LoadingIndicatorType
	glueCard: GlueCardType
	listView: ListViewType
	selfButton: SelfButtonType
	teamSelector: TeamSelectorType
	orgIcon: OrgIconType
	orgAccordion: OrgAccordionType
	manageTeamMembers: ManageTeamMembersType
	teamMemberDetails: TeamMemberDetailsType
	infoDialog: InfoDialogType
	errorDialog: GeneralDialogType
	spaces: SpacesType
	homePage: HomePageType
	imagePreview: ImgPreviewType
	files: FilesType
	createTeamDialog: CreateTeamDialogType
	avatarEditor: AvatarEditorType
	selectOutline: SelectOutlineType
	hoverOverlay: HoverOverlayType
	tag: TagType
	gotoSpaceDialog: GotoSpaceType
	spaceTemplatesDialog: SpaceTemplatesDialogType
	clock: ClockType
};

declare module '@material-ui/core/styles' {
	interface Theme {
		glueSpacing: (preset: string) => string | number
		custom: CustomThemeProps
	}
}

declare module '@material-ui/core/styles/createTheme' {
	interface ThemeOptions {
		glueSpacing: (preset: string) => string | number
		custom: CustomThemeProps
	}
}

declare module '@material-ui/core/styles/createPalette' {
	interface SimplePaletteColorOptions {
		active?: string
		textActive?: string
	}
	interface PaletteColor {
		active: string
		textActive: string
	}
	interface PaletteOptions {
		destructive?: PaletteColorOptions
		stealth?: PaletteColorOptions
		link?: PaletteColorOptions
		speechToggle?: PaletteColorOptions
		invertedSpeechToggle?: PaletteColorOptions
		speech?: PaletteColorOptions
		highlight?: PaletteColorOptions
		blue?: PaletteColorOptions
		green?: PaletteColorOptions
		yellow?: PaletteColorOptions
		purple?: PaletteColorOptions
		red?: PaletteColorOptions
		orange?: PaletteColorOptions
		switch?: PaletteColorOptions
	}
	interface Palette {
		destructive: PaletteColor
		stealth: PaletteColor
		link: PaletteColor
		speechToggle: PaletteColor
		invertedSpeechToggle: PaletteColor
		speech: PaletteColor
		highlight: PaletteColor
		blue: PaletteColor
		green: PaletteColor
		yellow: PaletteColor
		purple: PaletteColor
		red: PaletteColor
		orange: PaletteColor
		switch: PaletteColor
	}
}

declare module '@material-ui/core/styles/overrides' {
	interface ComponentNameToClassKey {
		[customKey: string]: CSSProperties
	}
}

const palette: PaletteOptions = {
	type: 'dark',

	primary: {
		main: paletteCommon.blue600,
		light: paletteCommon.blue400,
		dark: paletteCommon.blue900,
		active: paletteCommon.white,
		contrastText: paletteCommon.white,
		textActive: paletteCommon.blue600
	},

	secondary: {
		main: paletteCommon.grey800,
		light: paletteCommon.grey700,
		dark: paletteCommon.grey900,
		active: paletteCommon.white,
		contrastText: paletteCommon.white,
		textActive: paletteCommon.blue600,
	},

	destructive: {
		main: paletteCommon.red500,
		active: paletteCommon.white,
		contrastText: paletteCommon.white,
		textActive: paletteCommon.red500
	},

	success: {
		main: paletteCommon.success,
	},
	
	warning: {
		main: paletteCommon.alert
	},

	error: {
		main: paletteCommon.red400,
		dark: paletteCommon.red800,
		contrastText: paletteCommon.red200,
	},

	background: {
		default: paletteCommon.black,
		paper: paletteCommon.grey950,
	},

	stealth: {
		main: 'none',
		active: paletteCommon.white,
		contrastText: paletteCommon.white,
		textActive: paletteCommon.blue600
	},

	link: {
		main: 'none',
		active: 'none',
		contrastText: paletteCommon.blue700,
		textActive: paletteCommon.white,
	},

	speechToggle: {
		main: paletteCommon.grey800,
		active: `radial-gradient(${paletteCommon.yellow500}, ${paletteCommon.orange500} 90%)`,
		contrastText: paletteCommon.white,
		textActive: paletteCommon.white
	},

	// This name makes no sense
	invertedSpeechToggle: {
		main: '#0E1012',
		active: `radial-gradient(${paletteCommon.yellow500}, ${paletteCommon.orange500} 90%)`,
		contrastText: paletteCommon.white,
		textActive: paletteCommon.white
	},

	speech: {
		main: paletteCommon.orange500
	},

	highlight: {
		main: paletteCommon.blue800,
		dark: paletteCommon.blue950,
		contrastText: paletteCommon.blue200
	},

	blue: {
		main: paletteCommon.blue700,
		contrastText: paletteCommon.blue100
	},

	green: {
		main: paletteCommon.green700,
		contrastText: paletteCommon.green100
	},

	yellow: {
		main: paletteCommon.yellow700,
		contrastText: paletteCommon.yellow100
	},

	purple: {
		main: paletteCommon.purple700,
		contrastText: paletteCommon.purple100
	},

	red: {
		main: paletteCommon.red600,
		contrastText: paletteCommon.red100
	},

	orange: {
		main: paletteCommon.orange600,
		contrastText: paletteCommon.orange100
	},

	switch: {
		main: paletteCommon.grey400,
		contrastText: paletteCommon.grey950,
		active: paletteCommon.white,
		textActive: paletteCommon.blue600,
	}
};

const overrides: Overrides = {
	MuiCssBaseline: {
		'@global': {
			'@font-face': [sourceSansPro_Regular, sourceSansPro_SemiBold, sourceSansPro_Bold, sriracha_Regular, sourceSerifPro_Regular, sourceSerifPro_Bold],

			body: {
				fontSize: '24px',
				fontWeight: 400,
        lineHeight: '32px'
			},

			p: {
				margin: 0,
				fontSize: '24px',
				fontWeight: 400,
        lineHeight: '32px'
			},

			h1: {
				margin: 0,
				fontSize: '40px',
				fontWeight: 600,
        lineHeight: '40px'
			},

			h2: {
				margin: 0,
				fontSize: '32px',
				fontWeight: 600,
        lineHeight: '32px'
			},

			h3: {
				margin: 0,
				fontSize: '28px',
				fontWeight: 600,
        lineHeight: '28px',
				letterSpacing: '-0.01em'
			},
		},
	},

	MuiTypography: {
		root: {
			color: paletteCommon.white,
			opacity: '60%'
		},

		h1: {
			margin: 0,
			fontSize: '40px',
			fontWeight: 600,
      lineHeight: '40px'
		},

		h2: {
			margin: 0,
			fontSize: '32px',
			fontWeight: 600,
      lineHeight: '32px'
		},

		h3: {
			margin: 0,
			fontSize: '28px',
			fontWeight: 600,
      lineHeight: '28px',
			letterSpacing: '-0.01em'
		},

		overline: {
			fontSize: '18px',
			textTransform: 'uppercase',
			fontWeight: 700,
			letterSpacing: '1px',
			lineHeight: '18px',
		},

		body2: {
			fontWeight: 600,
			fontSize: '28px',
			textTransform: 'none',
			letterSpacing: 'normal',
			lineHeight: '40px'
		}
	},

	MuiButtonBase: {
		// If you change the font styles here, also change GlueCard, they should match
		root: {
			fontFamily: 'Source Sans Pro',
			fontWeight: 600,
			letterSpacing: 'normal',
			fontSize: '24px',
			lineHeight: '32px',
			background: palette.background?.paper,
			color: (palette.secondary as SimplePaletteColorOptions)?.contrastText,
			height: '64px',

			'&>.MuiSvgIcon-root': {
				fontSize: '36px'
			}
		},
	},

	MuiDrawer: {
		paper: {
			minWidth: '325px'
		}
	},

	MuiMenu: {
		paper: {
			minWidth: '180px',
			background: '#454A52',
		}
	},

	MuiMenuItem: {
		root: {
			borderRadius: 0,
			textTransform: 'none',
			letterSpacing: 0,
			background: 'none',

			paddingLeft: '24px',
			paddingRight: '24px',

			display: 'flex',
			justifyContent: 'space-between',

			'&:last-child': {
				borderBottom: 0
			},

			'&>.MuiSvgIcon-root': {
				marginLeft: '24px',
				fontSize: '24px'
			}
		}
	},

	MuiDialog: {
		paper: {
			padding: '12px',
			margin: '24px'
		},
	},

	MuiDialogTitle: {
		root: {
			padding: 0,
			textAlign: 'center'
		}
	},

	MuiDialogContent: {
		root: {
			margin: '24px 0 0 0',
			padding: 0
		}
	},

	MuiDialogContentText: {
		root: {
			textAlign: 'center'
		}
	},

	MuiDialogActions: {
		root: {
			marginTop: '36px',
			display: 'flex',
			flexFlow: 'row nowrap',
			justifyContent: 'center',
			padding: 0
		}
	},

	MuiPaper: {
		rounded: {
			borderRadius: '44px'
		}
	},

	MuiIconButton: {
		root: {
			width: '64px',
			height: '64px',
		}
	},

	MuiSnackbarContent: {
		root: {
			backgroundColor: '#D5DADE', 
			height: '48px',
			boxShadow: '0 5px 10px rgba(0,0,0,0.75)',	

			'& .MuiSnackbarContent-message':{
				padding: 0
			}			
		},

	},

	MuiTooltip: {
		tooltip: {
			color: 'black',
			backgroundColor: '#D5DADE', 
			height: '48px',
			fontSize: '24px',
			maxWidth: 'none',
			padding: '6px 24px',
			top: '7px',
			boxShadow: '0 5px 10px rgba(0,0,0,0.75)',	
		},

		arrow: {
			color: '#D5DADE', 
		}

	},

	MuiCheckbox: {
		root: {
			height: '36px',
			with: '36px',

			'&:hover': {
				backgroundColor: 'transparent',
				filter: 'brightness(1.5)'
			}
		},

		colorSecondary: {
			color: '#808080',
			'&$checked': {
				color: (palette.secondary as SimplePaletteColorOptions)?.active,
				'&:hover': {
					backgroundColor: 'transparent'
				},
				'& .MuiIconButton-label': {
					position: 'relative',
					zIndex: 0
				},
				'& .MuiIconButton-label:after': {
					content: '""',
					left: '8px',
					height: '30px',
					width: '30px',
					position: 'absolute',
					background: (palette.secondary as SimplePaletteColorOptions)?.textActive,
					zIndex: -1
				}
			}
		},
	},

	MuiLinearProgress: {
		root: {
			height: '12px',
			borderRadius: '6px'
		},
		colorPrimary: {
			backgroundColor: paletteCommon.grey900
		},
		barColorPrimary: {
			background: `repeating-linear-gradient(126deg, ${paletteCommon.blue500} 0px,${paletteCommon.blue500} 8px,${paletteCommon.blue600} 8px,${paletteCommon.blue600} 24px,${paletteCommon.blue500} 24px,${paletteCommon.blue500} 32px)`
		},
		bar1Indeterminate: {
			background: paletteCommon.blue500
		},
		bar2Indeterminate: {
			background: paletteCommon.blue500
		}
	}

};

const overridesWeb : Overrides = {
	MuiCssBaseline: {
		'@global': {
			'@font-face': [sourceSansPro_Regular, sourceSansPro_SemiBold, sourceSansPro_Bold],

			body: {
				fontSize: '16px',
				fontWeight: 400,
				userSelect: 'text',
				lineHeight: '24px'
			},

			p: {
				margin: 0,
				fontSize: '16px',
				fontWeight: 400,
				lineHeight: '24px'
			},

			body1: {
				fontSize: '16px'
			},

			h1: {
				margin: 0,
				fontSize: '32px',
				fontWeight: 600,
				lineHeight: '32px'
			},

			h2: {
				margin: 0,
				fontSize: '24px',
				fontWeight: 600,
				lineHeight: '24px'
			},

			h3: {
				margin: 0,
				fontSize: '20px',
				fontWeight: 600,
				lineHeight: '20px',
				letterSpacing: '-0.01em'
			}
		}
	},

	MuiTypography: {
		root: {
			color: paletteCommon.white,
			opacity: '60%'
		},

		body1: {
			fontSize: '16px'
		},

		h1: {
			margin: 0,
			fontSize: '32px',
			fontWeight: 600,
			lineHeight: '32px',
			letterSpacing: '-0.025em'
		},

		h2: {
			margin: 0,
			fontSize: '24px',
			fontWeight: 600,
			lineHeight: '24px',
			letterSpacing: '-0.025em'
		},

		h3: {
			margin: 0,
			fontSize: '20px',
			fontWeight: 600,
			lineHeight: '20px',
			letterSpacing: '-0.01em'
		},

		overline: {
			fontSize: '14px',
			textTransform: 'uppercase',
			letterSpacing: '1px',
			fontWeight: 700,
			lineHeight: '14px'
		},

		body2: {
			fontWeight: 400,
			fontSize: '20px',
			textTransform: 'none',
			lineHeight: '32px',
			letterSpacing: '-0.01em'
		}
	},

	MuiButtonBase: {
		// If you change the font styles here, also change GlueCard, they should match
		root: {
			fontFamily: 'Source Sans Pro',
			fontWeight: 600,
			letterSpacing: 'normal',
			fontSize: '16px',
			lineHeight: '20px',
			background: (palette.secondary as SimplePaletteColorOptions)?.main,
			color: (palette.secondary as SimplePaletteColorOptions)?.contrastText,
			height: '36px',

			'&>.MuiSvgIcon-root': {
				fontSize: '20px'
			}
		},
	},

	MuiDrawer: {
		paper: {
			minWidth: '225px'
		}
	},

	MuiMenu: {
		paper: {
			minWidth: '100px',
			background: '#454A52',
		},
	},

	MuiMenuItem: {
		root: {
			borderRadius: 0,
			textTransform: 'none',
			letterSpacing: 0,
			background: 'none',

			paddingLeft: '24px',
			paddingRight: '24px',

			display: 'flex',
			justifyContent: 'space-between',

			'&:last-child': {
				borderBottom: 0
			},

			'&>.MuiSvgIcon-root': {
				marginLeft: '24px',
				fontSize: '12px'
			}
		}
	},

	MuiDialog: {
		paper: {
			padding: '8px',
			borderRadius: '24px'
		}
	},

	MuiDialogTitle: {
		root: {
			padding: 0,
			textAlign: 'center'
		}
	},

	MuiDialogContent: {
		root: {
			margin: '12px 0 0 0',
			padding: 0
		}
	},

	MuiDialogContentText: {
		root: {
			textAlign: 'center'
		}
	},

	MuiDialogActions: {
		root: {
			marginTop: '36px',
			padding: 0,
			justifyContent: 'center',
		},
		spacing: {
			'& > :not(:first-child)': {
				marginLeft: '0px'
			}
		}
	},

	MuiPaper: {
		rounded: {
			borderRadius: '26px'
		}
	},

	MuiGlueSwitchSelector: {
		root: {
			gridTemplateColumns: '1fr 36px 1fr',
			gap: '18px',

			'& p': {
				fontSize: '16px',
				fontWeight: '600',
				letterSpacing: '0',
				lineHeight: '20px',
			}
		},

		switchRoot: {
			width: '36px',
			height: '18px',
			'& .Mui-checked': {
				transform: 'translateX(18px)',
			}
		},

		switchBase: {
			padding: '2px',
			width: '18px',
			height: '18px'
		},

		thumb: {
			width: '14px',
			height: '14px'
		}
	},

	MuiGlueSliderSelector: {
		thumb: {
			width: '12px',
			height: '12px',

			'& img': {
				width: '36px',
				height: '36px',
			},

			'& .outerRing': {
				height: '24px',
				width: '24px',
				borderRadius: '12px',
			},
	
			'& .innerRing': {
				height: '20px',
				width: '20px',
				borderRadius: '10px',
			},
	
			'& .indicatorRing': {
				height: '16px',
				width: '16px',
				borderRadius: '8px',
			}
		},

		rail: {
			height: '8px'
		}
	},

	MuiGlueAvatarOverview: {
		nameInput: {
			justifyContent: '',
			gap: '18px',

			'& .MuiGlueInputField-searchfield': {
				width: '452px',
				height: '64px',
				borderRadius: '18px',
				borderWidth: '0'
			},

			'& input': {
				fontSize: '42px',
				letterSpacing: '-20',
			}
		},

		nameGuide: {
			fontSize: '16px',
			fontWeight: '400',
			letterSpacing: '0',
			lineHeight: '20px',
			opacity: '70%'
		}
	},

	MuiCheckbox: {
		root: {
			height: '36px',
			with: '36px',

			'&:hover': {
				backgroundColor: 'transparent',
				filter: 'brightness(1.5)'
			}
		},

		colorSecondary: {
			color: '#808080',
			'&$checked': {
				color: (palette.secondary as SimplePaletteColorOptions)?.active,
				'&:hover': {
					backgroundColor: 'transparent'
				},
				'& .MuiIconButton-label': {
					position: 'relative',
					zIndex: 0
				},
				'& .MuiIconButton-label:after': {
					content: '""',
					left: 1,
					top: 4,
					height: '16px',
					width: '16px',
					position: 'absolute',
					background: (palette.secondary as SimplePaletteColorOptions)?.textActive,
					zIndex: -1
				}
			}
		},
	},

	MuiIconButton: {
		root: {
			width: '36px',
			height: '36px',
			padding: '2px',
		}
	},

	MuiSvgIcon: {
		root: {
			fontSize: '24px'
		}
	},

	MuiSnackbarContent: {
		root: {
			backgroundColor: '#D5DADE', 
			height: '36px',
			boxShadow: '0 5px 10px rgba(0,0,0,0.75)',	

			'& .MuiSnackbarContent-message':{
				padding: 0
			}			
		},

	},

	MuiTooltip: {
		tooltip: {
			color: 'black',
			backgroundColor: '#D5DADE', 
			height: '36px',
			fontSize: '16px',
			maxWidth: 'none',
			padding: '6px 16px',
			top: '7px',
			boxShadow: '0 5px 10px rgba(0,0,0,0.75)',	
		},

		arrow: {
			color: '#D5DADE', 
		}

	},

	MuiLinearProgress: {
		root: {
			height: '8px',
			borderRadius: '4px'
		},
		colorPrimary: {
			backgroundColor: paletteCommon.grey900
		},
		barColorPrimary: {
			background: `repeating-linear-gradient(126deg, ${paletteCommon.blue500} 0px,${paletteCommon.blue500} 5px,${paletteCommon.blue600} 5px,${paletteCommon.blue600} 15px,${paletteCommon.blue500} 15px,${paletteCommon.blue500} 20px)`
		},
		bar1Indeterminate: {
			background: paletteCommon.blue500
		},
		bar2Indeterminate: {
			background: paletteCommon.blue500
		}
	}

};

const typography =
{
	fontFamily: ['Source Sans Pro', 'sans-serif', 'sriracha'].join(','),
	fontWeightRegular: 400,
	fontWeightBold: 700,
	fontSize: 24
};

const typographyWeb = 
{
	fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
	fontWeightRegular: 400,
	fontWeightBold: 700,
	fontSize: 24
};

const spacing = (preset: string) =>
{
	switch(preset) {
		case 'xs':
			return '4px';
		case 's':
			return '12px';
		case 'm':
			return '24px';
		case 'l':
			return '36px';
		case 'xl':
			return '48px';
		default:
			return 0;
	}
};

const spacingWeb = (preset: string) =>
{
	switch(preset) {
		case 'xs':
			return '4px';
		case 's':
			return '8px';
		case 'm':
			return '16px';
		case 'l':
			return '24px';
		case 'xl':
			return '36px';
		default:
			return 0;
	}
};

const webTheme = createTheme({
	palette: palette,
	overrides: overridesWeb,
	typography: typographyWeb,
	glueSpacing: spacingWeb,
	custom: {
		// Scalable content base with breakpoints
		contentBase: ContentBaseWeb,

		// Common
		glueButton: GlueButtonWeb,
		glueToggle: GlueToggleWeb,
		glueRadio: GlueRadioWeb,
		glueAccordion: GlueAccordionWeb,
		glueEditor: GlueEditorWeb,
		editorPanel: EditorPanelWeb,
		colorPicker: ColorPickerWeb,
		pageSelector: PageSelectorWeb,
		glueTab: GlueTabWeb,
		header: HeaderWeb,
		glueScroll: GlueScrollWeb,
		glueDropdown: GlueDropdownWeb,
		glueListItem: GlueListItemWeb,
		view: ViewWeb,
		icon: IconWeb,
		loadingIndicator: LoadingIndicatorWeb,
		glueCard: GlueCardWeb,
		listView: ListViewWeb,
		selectOutline: SelectOutlineWeb,
		hoverOverlay: HoverOverlayWeb,
		tag: TagWeb,

		// Special
		selfButton: SelfButtonWeb,
		teamSelector: TeamSelectorWeb,
		orgIcon: OrgIconWeb,
		orgAccordion: OrgAccordionWeb,
		manageTeamMembers: ManageTeamMembersWeb,
		teamMemberDetails: TeamMemberDetailsWeb,
		infoDialog: InfoDialogWeb,
		errorDialog: GeneralDialogWeb,
		spaces: SpacesWeb,
		homePage: HomePageWeb,
		imagePreview: ImgPreviewWeb,
		files: FilesWeb,
		createTeamDialog: CreateTeamDialogWeb,
		gotoSpaceDialog: GotoSpaceWeb,
		spaceTemplatesDialog: SpaceTemplatesDialogWeb,
		clock: ClockWeb,

		// Avatar
		avatarEditor: AvatarEditorWeb,
	}
})

const glueTheme = createTheme({
	palette: palette,
	overrides: overrides,
	typography: typography,
	glueSpacing: spacing,
	custom: {
		// Scalable content base with breakpoints (In tablet it's always width 100%)
		contentBase: ContentBaseTablet,

		// Common
		glueButton: GlueButtonTablet,
		glueToggle: GlueToggleTablet,
		glueRadio: GlueRadioTablet,
		glueAccordion: GlueAccordionTablet,
		glueEditor: GlueEditorTablet,
		editorPanel: EditorPanelTablet,
		colorPicker: ColorPickerTablet,
		pageSelector: PageSelectorTablet,
		glueTab: GlueTabTablet,
		header: HeaderTablet,
		glueScroll: GlueScrollTablet,
		glueDropdown: GlueDropdownTablet,
		glueListItem: GlueListItemTablet,
		view: ViewTablet,
		icon: IconTablet,
		loadingIndicator: LoadingIndicatorTablet,
		glueCard: GlueCardTablet,
		listView: ListViewTablet,
		selectOutline: SelectOutlineTablet,
		hoverOverlay: HoverOverlayTablet,
		tag: TagTablet,

		// Special
		selfButton: SelfButtonTablet,
		teamSelector: TeamSelectorTablet,
		orgIcon: OrgIconTablet,
		orgAccordion: OrgAccordionTablet,
		manageTeamMembers: ManageTeamMembersTablet,
		teamMemberDetails: TeamMemberDetailsTablet,
		infoDialog: InfoDialogTablet,
		errorDialog: GeneralDialogTablet,
		spaces: SpacesTablet,
		homePage: HomePageTablet,
		imagePreview: ImgPreviewTablet,
		files: FilesTablet,
		createTeamDialog: CreateTeamDialogTablet,
		gotoSpaceDialog: GotoSpaceTablet,
		spaceTemplatesDialog: SpaceTemplatesDialogTablet,
		clock: ClockTablet,

		// Avatar
		avatarEditor: AvatarEditorTablet,
	}
});

export const WebTheme = webTheme;
export const GlueTheme = glueTheme;

export const SourceSerif = sourceSerifPro_Regular;

export const Sriracha = sriracha_Regular;
