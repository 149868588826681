import { ApolloClient } from '@apollo/client';
import axios from 'axios';
import { CancelTokenSource } from 'axios';
import queries from '../../graphql/queries';
import { Asset, AssetVersion, ProcessingStatus, SignedUploadUrlResponse } from '../../graphql/types-generated';
import checkStatusItem from '../../util/check-status-item';
import mutations from '../../graphql/mutations';

export const COMMON_ASSET_VERSION_POINTER_NAMES =
[
	'testing', 'RC', 'production'
];

export type AssetProcessingResult = {
	version: AssetVersion
	asset: Asset
};

export const assetVisibility = Object.freeze({
	public: 'public',
	limited: 'private',
	team: 'team',
	org: 'org'
});

export type AssetUploadResult = {
	statusItemId: string
};

export const uploadSpaceAssetPackageAsync = async (
	signedUploadURLBody: SignedUploadUrlResponse,
	file: File, 
	setProgress: (uploadProgress: number) => void, 
	setCancelSource: (uploadCancelSource?: CancelTokenSource) => void): Promise<AssetUploadResult> =>
{
	setProgress(0);

	const cancelSource = axios.CancelToken.source();

	const uploadFileConfig = {
		headers: {
			'Content-Type': file.type
		},
		cancelToken: cancelSource.token,
		onUploadProgress: (progressEvent: ProgressEvent) => {
			setProgress(progressEvent.loaded / progressEvent.total);
		}
	};

	const tags = new URLSearchParams(signedUploadURLBody.tagsStr);
	const statusItemId = tags.get('statusItemId');
	if (!statusItemId)
		throw new Error("Status item ID not found");

	setCancelSource(cancelSource);

	try {
		await axios.put(
			signedUploadURLBody.uploadURL,
			file,
			uploadFileConfig
		);
	}
	catch (e)
	{
		setProgress(-1);
		setCancelSource(undefined);
		
		if (e === cancelSource.token.reason)
			console.log("Uploading file was canceled");
		else
			throw e;
	}

	setProgress(1);
	setCancelSource(undefined);

	return { statusItemId };
};

export const checkSpaceAssetUploadUploadStatus = (
	stausItemId: string, 
	apollo: ApolloClient<unknown>, 
	statusInfoCallback?: ((statusInfo: ProcessingStatus) => void) | null) => new Promise<AssetProcessingResult>((resolve, reject) =>
{
	const checkStatus = async () =>
	{
		const statusRes = await apollo.query({
			query: queries.processingStatusInfo,
			fetchPolicy: 'network-only',
			errorPolicy: 'all',
			variables: {
				statusItemId: stausItemId
			}
		}).catch(e => {
			console.error("Status item query failed", e);
			return null;
		});

		const statusInfo: ProcessingStatus = statusRes?.data?.processingStatusInfo ?? null;
		if (!statusInfo)
		{
			reject(null);
			return;
		}

		console.log("Space Asset Package status check", statusInfo);

		if (statusInfoCallback)
			statusInfoCallback(statusInfo);

		if (statusInfo.statuscode === 'Processing' || statusInfo.statuscode === 'Upload')
		{
			setTimeout(checkStatus, 250);
		}
		else if (statusInfo.statuscode === 'Failed')
		{
			if (statusInfo.result) {
				const parsedError = JSON.parse(statusInfo.result);
				reject(new Error(parsedError.error));
			} else reject(new Error('Unknown error'));
		}
		else if (statusInfo.statuscode === 'Finished')
		{
			const parsedResult = await new Promise<AssetProcessingResult>((resolve, reject) => { try { if (!statusInfo.result) {throw{}} resolve(JSON.parse(statusInfo.result)) } catch (e) { reject(e) }}).catch(e => undefined);
			if (!parsedResult) {
				reject(null);
				return;
			}
			resolve(parsedResult);
		}
		else
		{
			console.error("Status item has unexpected statuscode", statusInfo);
			reject();
		}
	};

	checkStatus();
});

export const setVersionPointer = async (
	apollo: ApolloClient<unknown>,
	assetId: string, 
	pointerName: string, 
	pointerVersion: number, 
	serverComponentsImageTagOverride?: string): Promise<boolean> =>
{
	return await apollo.mutate({
		mutation: mutations.setAssetVersionPointer,
		variables: {
			assetId: assetId,
			pointerName: pointerName,
			version: pointerVersion,
			serverComponentsImageTagOverride: serverComponentsImageTagOverride
		}
	}).then(
		() => true,
		(e) => {
			console.error("Failed to set version pointer", e, assetId);
			return false;
		}
	)
};

export const setTrackVersionPointer = (apollo: ApolloClient<unknown>, spaceId: string, pointerName: string) =>
{
	return apollo.mutate({
		mutation: mutations.setSpaceTrackVersionPointer,
		variables: {
			roomId: spaceId,
			pointerName: pointerName,
		}
	});
};

export const uploadAssetThumbnailImage = async (
	apollo: ApolloClient<unknown>, 
	imageFile: File, 
	assetId: string): Promise<string | null> =>
{
	const uploadInfoRes = await apollo.mutate({
		mutation: mutations.generateAssetThumbnailUploadURL,
		variables: {
			fileType: imageFile.type,
			assetId: assetId
		}
	});
	const uploadInfo: SignedUploadUrlResponse = uploadInfoRes.data.generateAssetThumbnailUploadURL;

	const uploadSuccess = await fetch(
		uploadInfo.uploadURL,
		{
			method: 'PUT',
			headers: {
				'Content-Type': imageFile.type
			},
			body: imageFile
		}
	).then(
		value => true,
		e => {
			console.error("Uploading thumbnail file failed", e);
			return false;
		}
	);

	return uploadSuccess ? uploadInfo.statusItemId : null;
};

export const assignCustomThumbnail = async (file: File, assetId: string, apollo: ApolloClient<unknown>) => {
	const statusItemId = await uploadAssetThumbnailImage(
		apollo,
		file,
		assetId
	);

	if (!statusItemId)
	{
		throw new Error('Thumbnail upload failed');
	}

	const statusRes = await new Promise((resolve, reject) =>
	{
		const check = async () => {
			const statusInfo = await checkStatusItem(apollo, statusItemId);
			console.log("Thumbnail upload status", statusInfo);

			if (!statusInfo || statusInfo.statuscode === 'Failed')
			{
				resolve(false);
				return;
			}

			if (statusInfo.statuscode === 'Finished')
			{
				resolve(true);
				return;
			}

			setTimeout(check, 500);
		};

		check();
	});

	if (!statusRes)
	{
		throw new Error('Thumbnail upload failed');
	}
}
