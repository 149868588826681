import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { makeStyles, ButtonBase } from '@material-ui/core';

import { clickAudio, hoverAudio } from './common-vuplex-messages';

export const HeroIconContext = React.createContext({ narrow: false });

const useStyles = makeStyles(theme => ({
	root: ({ props, context }) => ({
		position: 'relative',
		width: context.narrow ? '80px' : '256px',

		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',

		'&:hover': {
			'& $iconContainer': {
				background: context.narrow ?
					( props.disabled ? 'none' : 'rgba(255, 255, 255, 0.1)') :
					( props.disabled ? null : '#3E4247'),
			},

			'& $name': {
				opacity: props.disabled ? '50%' : '100%'
			}
		}
	}),

	iconContainer: ({ props, context }) => ({
		width: '100%',
		height: context.narrow ? '80px' : '128px',

		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		borderRadius: '99999999px',
		background: props.noBackground ? "transparent" : theme.palette.background.paper,
		color: props.toggled ? '#000' : '#fff',

		'& svg': {
			fontSize: '80px'
		},

		'&:disabled': {
			opacity: '50%',
			filter: context.narrow ? null : 'saturate(10%)'
		}
	}),

	icon: {
		width: '80px',
		height: '80px',
		display: 'block'
	},

	name: ({ props, context }) => ({
		flex: '0 0 auto',
		marginTop: context.narrow ? '4px' : '8px',

		color: '#fff',
		fontSize: '24px',
		lineHeight: '24px',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',

		opacity: props.disabled ? '30%' : '70%',
	}),

	bottom: {
		flex: '1 0 auto',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		width: '100%'
	},

	hotkey: {
		position: 'absolute',
		top: -8,
		left: ({ props, context }) => props.hotkeyCenter ? null : 0,
		fontSize: '16px',
		opacity: '50%'
	}
}));

const onClickIcon = (props, navigate) =>
{
	if (typeof props.onPointerDown === 'function')
	{
		props.onPointerDown();
	}

	if (props.route)
	{
		navigate(props.route);
	}
};

const chooseIcon = (props) =>
{
	if ((typeof props.toggled !== 'undefined') && !props.toggled)
		return props.iconOff;
	else
		return props.icon;
};

const HeroIcon = (props) =>
{
	const context = useContext(HeroIconContext);
	const navigate = useNavigate();
	const classes = useStyles({ props, context });

	const audiomessage = !!props.uiAudioMessage ? props.uiAudioMessage : "Menu/Generic/Button/Press";
	const hoveraudiomessage = !!props.uiHoverAudioMessage ? props.uiHoverAudioMessage : "Menu/Generic/Button/HL";

	return (
		<div className={classes.root}>
			<ButtonBase className={classes.iconContainer} disabled={props.disabled}
				onPointerDown={() => {onClickIcon(props, navigate); clickAudio(audiomessage)} }
				onPointerEnter={() => {hoverAudio(hoveraudiomessage)}}
			>
				<img className={classes.icon} src={chooseIcon(props)} alt={props.name} />
			</ButtonBase>

			{!props.disableName && (
				<div className={classes.name}>
					{props.name}
				</div>
			)}

			{!!props.bottom && (
				<div className={classes.bottom}>
					{props.bottom}
				</div>
			)}

			{context.showHotkey && !!props.hotkey && (
				<div className={classes.hotkey}>{props.hotkey}</div>
			)}
		</div>
	);
};

export default HeroIcon;
