import React, { useEffect, useState, useRef, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import postVuplexMessage from '../service/message-vuplex';

import BatteryIndicator from './battery-indicator';
import VRHeroIcon from './vrmode-hero-icon';
import ClockNow from './clock/clock-now';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import queries from '../graphql/queries';
import { ClockStopwatchDisplay } from './clock/clock-stopwatch';
import { ClockTimerDisplay } from './clock/clock-timer';
import { SessionTimer } from './session-time';
import { isAtHome } from '../util/space-utils';
import SVGIconWrapper from './common/svg-icon-wrapper';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import GlueButton from './common/glue-button'

import ToolbarMain from './toolbar-main';
import GestureMenu from './gestures-menu';
import TransformToolbar from './object-manipulation/transform-ui-toolbar';
import ToolbarVR from './toolbar-vr';

import ResizeObserver from 'resize-observer-polyfill';
import { broadcastMessage } from '../service/message-broadcast';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		flex: '0 0 auto',
		width: ({isVRHUD}) => isVRHUD ? '384px' : '1270px',

		display: 'flex',
		flexFlow: 'column nowrap',

		overflow: 'hidden',
	},

	statusbar: {
		background: theme.palette.secondary.dark,
		height: '64px',
		padding: '0 4px 0 4px',

		display: 'grid',
		gridTemplateRows: 'auto',
		gridTemplateColumns: '83px 250px 180px 100px 180px 129px 200px',
		alignItems: 'center',
		gap: '24px'
	},

	contents: {
		height: '144px',
		background: theme.palette.background.paper,
	},

	minimizedStatusbar: {
		background: theme.palette.secondary.dark,
		height: '64px',
		padding: '14px 4px 0 4px',
		alignItems: 'center',
	},

	statusbarRight: {
		marginRight: '6px',
		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'flex-end',
		alignItems: 'center'
	},

	statusbarLeft: {
		marginLeft: '6px',
		textAlign: 'center',
		alignItems: 'center',
		minWidth: '96px'
	},

	closeButton: {
		display: 'block',
		width: '80px',
		height: '80px',
		color: 'white'
	}
}), { name: 'MuiGlueToolbar' });

const Toolbar = (props) =>
{
	const apollo = useApolloClient();
	const navigate = useNavigate();
	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);

	const uiRes = useQuery(queries.ui);
	const ui = uiRes.data?.ui;
	const isToolbar = ui === 'toolbar';
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;
	
	const vrModeEnabled = clientPlatform?.PlatformType === "VR";
	const isVRHUD = vrModeEnabled && isToolbar;
	const allowVRToggle = (vrModeEnabled && clientPlatform?.Capabilities?.includes("KBAM")) || (!vrModeEnabled && clientPlatform?.Capabilities?.includes("VR"));

	const tabletOpenRes = useQuery(queries.tabletOpen);
	const tabletOpen = tabletOpenRes.data?.tabletOpen;

	const clockTimerRes = useQuery(queries.clockTimer);
	const clockStopwatchRes = useQuery(queries.clockStopwatch);

	const sessionTimeRes = useQuery(queries.sessionTimeRemaining);
	const toolbarModeRes = useQuery(queries.toolbarMode);
	const toolbarMode = toolbarModeRes.data?.toolbarMode;
	const classes = useStyles({ isVRHUD: isVRHUD });

	const [toolbarCurrent, setToolbarCurrent] = useState({ width: '', height: '' });

	const clickSound = "Tools/Settings/Press";
	const hoveraudiomessage = "Tools/Settings/HL";

	const sizeRef = useRef(null);

	const changeToolbarMode = (mode) =>
	{
		if (toolbarMode === mode)
			return;

		// Tablet should never have the transform menu open in toolbar
		if (!isToolbar && mode === 'transform')
			return;

		// TODO: Get rid of these (requires client change)
		if (toolbarMode === 'transform')
		{
			postVuplexMessage("Close Object transform edit");
		}
		else if (toolbarMode === 'gestures')
		{
			postVuplexMessage('Set Gesture mode', { value: false });
		}

		switch (mode)
		{
			case 'gestures':
				postVuplexMessage('Set Gesture mode', { value: true });
				break;
			case 'transform':
				postVuplexMessage('Enable hotkeys', { value: false });
				postVuplexMessage("Open Object transform edit");
				break;
			case 'main':
			default:
				postVuplexMessage('Enable hotkeys', { value: true });
				break;
		}

		apollo.writeQuery({
			query: queries.toolbarMode,
			data: { toolbarMode: mode }
		});
	}

	// Close transform menu when tablet is opened
	if (tabletOpen && toolbarMode === 'transform')
	{
		changeToolbarMode('main');
	}

	const toggleVR = (vrModeEnabled) =>
	{
		postVuplexMessage('Toggle VR', { value: vrModeEnabled });
	};

	const heroIconContext =
	{
		narrow: true,
		showHotkey: !vrModeEnabled
	};

	const [displaySessionTime, setDisplaySessionTime] = useState(false);

	const resizeToolbar = useCallback(() => {
		if (sizeRef.current.offsetHeight !== toolbarCurrent.height || sizeRef.current.offsetWidth !== toolbarCurrent.width) 
		{
			postVuplexMessage("Set Toolbar size", { width: sizeRef.current.offsetWidth, height: sizeRef.current.offsetHeight })
			setToolbarCurrent({ width: sizeRef.current.offsetWidth, height: sizeRef.current.offsetHeight})
		}
	}, [sizeRef, toolbarCurrent, setToolbarCurrent])

	const [toolbarResizeObserver] = useState(
		new ResizeObserver(entries => resizeToolbar())
	);

	useEffect(() => {
		
		if(sizeRef.current && isToolbar)
			toolbarResizeObserver.observe(sizeRef.current);
		else
			toolbarResizeObserver.disconnect();

		if (sessionTimeRes.data.sessionTimeRemaining / (60 * 1000) <= 5)
		{
			setDisplaySessionTime(true);
		}

		if (atHome)
		{
			setDisplaySessionTime(false);
			apollo.writeQuery({
				query: queries.sessionTimeRemaining,
				data: { sessionTimeRemaining: 21600000 }
			});
		}
		return () => {
			toolbarResizeObserver.disconnect();
		};
	}, [sessionTimeRes, atHome, apollo, sizeRef, toolbarResizeObserver, isToolbar]);

	const renderMainContent = () => {
		switch(toolbarMode)
		{
			case 'gestures':
				return (<GestureMenu vr={vrModeEnabled} heroIconContext={heroIconContext}/>);
			case 'transform':
				return (<TransformToolbar/>);
			case 'main':
			default:
				return (<ToolbarMain isToolbar={isToolbar} onModeChange={changeToolbarMode} heroIconContext={heroIconContext}/>);
		}
	}

	const onClickClock = () => {
		if (!isToolbar)
		{
			navigate('/app/clock/now');
		}
		else
		{
			postVuplexMessage('Open tablet', null);
			broadcastMessage({ topic: 'Navigate to', args: { ui: 'tablet', path: '/app/clock/now' }});
		}
	}

	return (
		<div className={classes.root} ref={sizeRef}>
			{isVRHUD ? 
			<div className={classes.minimizedStatusbar}>
				<ClockNow variant='toolbar' onClick={onClickClock} />
			</div> : 
			<div className={classes.statusbar}>
				<div className={classes.statusbarLeft}>
					{toolbarMode !== 'main' && !isVRHUD && (
					<GlueButton
						variant='compactIcon'
						onPointerDown={() => changeToolbarMode('main')}
						uiAudioMessage = {clickSound}
						uiHoverAudioMessage = {hoveraudiomessage}
					>
						<SVGIconWrapper toolbar><NavigateBeforeIcon style={{fontSize: '64px'}} /></SVGIconWrapper>
					</GlueButton>
					)}
					<div>{/* TODO: Space participant count and icon - edit: this might be going to the right side of the toolbar*/}</div>
				</div>

				{!displaySessionTime ? (
					<div></div>
				) : (
					<SessionTimer toolbar />
				)}

				{!clockStopwatchRes.data.clockStopwatch.running ?
				(
					<div></div>
				) : (
					<ClockStopwatchDisplay toolbar />
				)}

				<ClockNow variant='toolbar' onClick={onClickClock} />
				
				{!clockTimerRes.data.clockTimer.running && !clockTimerRes.data.clockTimer.alarm ?
				(
					<div></div>
				) : (
					<ClockTimerDisplay toolbar />
				)}

				<div></div>

				<div className={classes.statusbarRight}>
					<BatteryIndicator hideIfNotPresent />
					{!isVRHUD && allowVRToggle ? <VRHeroIcon toolbarHeader /> : <div/>}
				</div>
			</div>
			}
			{isVRHUD ? <ToolbarVR toggleVR={toggleVR}/> : renderMainContent()}
		</div>
	);
};

export default Toolbar;
