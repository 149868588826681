import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';
import queries from '../graphql/queries';
import { isAtHome } from '../util/space-utils';

import HomeOnIcon from '../icons/hero/Home_ON.png'
import HomeOffIcon from '../icons/hero/Home_OFF.png'

const HomeHeroIcon = (props) =>
{
	const currentSpaceServerKeyResult = useQuery(queries.currentSpaceServerKey);
	const atHome = isAtHome(currentSpaceServerKeyResult);

	const handleOpenDialog = () =>
	{
		postVuplexMessage('Request return home', null);
	};

	const hoveraudiomessage = "Tools/Home/HL";
	const audiomessage = "Tools/Home/Press";

	return (
		<HeroIcon
			name="Home"
			noBackground={props.noBackground}
			icon= {atHome ? HomeOffIcon : HomeOnIcon}
			disabled={atHome}
			onPointerDown={() => handleOpenDialog()}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
		/>
	);
};

export default HomeHeroIcon;
