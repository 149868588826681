import { ApolloClient, QueryResult } from "@apollo/client";
import { setTrackVersionPointer } from "../component/space-asset/space-asset-common";
import { Asset, Room } from "../graphql/types-generated";
import mutations from "../graphql/mutations";
import queries from "../graphql/queries";
import postVuplexMessage from "../service/message-vuplex"

export const joinSpace = async (apollo: ApolloClient<unknown>, roomId: string, takeEveryone: boolean) => 
{
	const roomInfoRes = await apollo.query({
		query: queries.roomInfo,
		variables: {
			roomId: roomId
		},
		fetchPolicy: 'network-only'
	});

	postVuplexMessage('Join space', { room: roomInfoRes.data.roomInfo, takeEveryone: takeEveryone });
};

const assetHasProductionPointer = (asset: Asset) =>
{
	const productionVersion = asset.versionPointers && asset.versionPointers.find((item) => item.name === "production");
	return !!productionVersion;
}

const setTrackedVersionPointer = async (apollo: ApolloClient<unknown>, roomId: string, pointerName: string) =>
{
	await setTrackVersionPointer(apollo, roomId, pointerName);
}

export const setSpaceThumbnail = async (apollo: ApolloClient<unknown>, roomId: string, thumbnailUrl: string) =>
{
	await apollo.mutate({
		mutation: mutations.updateSpaceInfo,
		variables: {
			roomId: roomId,
			thumbnailUrl: thumbnailUrl
		}
	});
}

export const createSpace = async (apollo: ApolloClient<unknown>, name: string, teamId: string, asset: Asset): Promise<Room> =>
{
	if (!assetHasProductionPointer(asset)) {
		throw new Error(`Asset ${asset.assetId} has no production pointer`);
	}

	const createRoomRes = await apollo.mutate({
		mutation: mutations.createRoom,
		variables: {
			name: name,
			teamId: teamId,
			assetName: asset.name,
			assetId: asset.assetId,
			sceneUrl: undefined,
			sceneName: asset.sceneName
		}
	});

	const room = createRoomRes.data.createRoom;
	await setTrackedVersionPointer(apollo, room.roomId, 'production');
	return room;
}

export const isAtHome = (currentSpaceServerKeyResult: QueryResult<{ currentSpaceServerKey: string }>) =>
{
	const atHome = !currentSpaceServerKeyResult.data?.currentSpaceServerKey || currentSpaceServerKeyResult.data.currentSpaceServerKey.length === 0;
	return atHome
};

export const requestCachedSpaceTemplates = () =>
{
	postVuplexMessage('Scene.GetCachedTemplateUrls');
};

export const refreshSpaces = async (apollo: ApolloClient<unknown>) =>
{
	requestCachedSpaceTemplates();
	await apollo.refetchQueries({
		include: [ queries.myRooms, queries.sessionInfo ]
	});
}
