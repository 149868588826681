import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';
import SwitchSelector from '../common/switch-selector';
import { postVuplexMessage } from '../../service/message-vuplex';

import queries from '../../graphql/queries';

import LoadingIndicator from '../common/loading-indicator';

import VideoTimeline from '../video-control/video-timeline';
import VideoControlFooter from '../video-control/video-control-footer';
import CloseButton from '../common/close-button';
import { InventoryItem, SignedInventoryItemGet } from '../../graphql/types-generated';
import OpenInNewButton from '../common/open-in-new-button';


export const isViewable = (inventoryItemInfo: InventoryItem) => {
	return inventoryItemInfo.itemType === 'video/mp4';
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: '962px',
		overflow: 'hidden',
		display: 'flex',
		flexFlow: 'column',
	},

	header: {
		display: 'flex',
		justifyContent: 'space-between',
		background: theme.palette.background.paper,
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		alignItems: 'center',
	},

	headerLeft: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		marginLeft: theme.glueSpacing('l')
	},

	headerCenter: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	headerRight: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	title: {
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},

	closeButton: {
		display: 'flex',
		justifyContent: 'center',
		width: '64px',
		justifySelf: 'right',
		alignItems: 'center',
	},

	video: {
		width: '1280px',
		height: '720px',
		resizeMode: 'cover',
		margin: 0,
	},

	loadingIndicatorWrapper: {
		display: 'flex',
		position: 'absolute',
		left: '0',
		right: '0',
		top: '0',
		bottom: '0',
		alignItems: 'center',
		justifyContent: 'center',
		pointerEvents: 'none'
	},

	loadingIndicator: {
		backgroundColor: 'transparent',
		height: 300,
		width: 300,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around'
	},

}), { name: 'MuiGlueFileViewVideo' });

const FileViewVideo = (props: {
	noPresenting: boolean
	videoName: string
	videoUrl: string
	// Shared viewer props
	teamId: string
	itemId: string
	closePreview: (e?: React.PointerEvent) => void
	itemInfo: InventoryItem
	signedURLs: SignedInventoryItemGet
}) =>
{
	const [volume, setVolume] = useState(1);
	const [muted, setMuted] = useState(false);
	const [paused, setPaused] = useState(true);
	const [looped, setLooped] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [buffering, setBuffering] = useState(false);	
	
	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;

	const SetVolume = (newValue: number) =>
	{
		setVolume(newValue);
		if (videoRef.current != null) {
			videoRef.current.volume = newValue;
		}
	}

	const SetVolumeMuted = (value: boolean) =>
	{
		setMuted(value);
		if (videoRef.current != null) {
			videoRef.current.muted = value;
		}
	}

	const SetPaused = (value: boolean) =>
	{
		setPaused(value);
		if (videoRef.current != null) {
			if (value === true) {
				videoRef.current.pause();
			}
			else {
				videoRef.current.play();
			}
		}
	}

	const SetLooped = (value: boolean) =>
	{
		setLooped(value);
		if (videoRef.current != null) {
			videoRef.current.loop = value;
		}
	}

	const Skip = (value: number) =>
	{
		if (videoRef.current != null) {
			videoRef.current.currentTime += value;
		}
	}

	const SeekTo = (value: number) =>
	{
		if (videoRef.current != null) {
			videoRef.current.currentTime = value;
		}
	}

	const OnEnded = () =>
	{
		setPaused(!videoRef.current?.loop);
	}

	const OnTimeUpdate = () =>
	{
		setCurrentTime(videoRef.current?.currentTime ?? 0);
	}

	const OnLoadedMetaData = () =>
	{
		setDuration(videoRef.current?.duration ?? 0);
	}

	const OnWaiting = () =>
	{
		setBuffering(true);
	}

	const OnPlaying = () =>
	{
		setBuffering(false);
	}

	const classes = useStyles();

	const videoRef = useRef<HTMLVideoElement>(null);

	const presentaudiomessage = "Menu/TeamFiles/VideoPlayer/Present/Press";
	const presenthoveraudiomessage = "Menu/TeamFiles/VideoPlayer/Present/HL";

	const PresentButton =
		<SwitchSelector
			text="Present"
			onChange={() => importVideo(true)}
			uiAudioMessage={presentaudiomessage}
			uiHoverAudioMessage={presenthoveraudiomessage}
			checked={false}
		/>

	const Video =
		<video
			className={classes.video}
			ref={videoRef}
			id="video"
			src={props.videoUrl ? props.videoUrl : props.signedURLs.itemURL}
			controls={false}
			onEnded={OnEnded}
			onTimeUpdate={OnTimeUpdate}
			onLoadedMetadata={OnLoadedMetaData}
			onPlaying={OnPlaying}
			onWaiting={OnWaiting}>
		</video>;

	const importVideo = (presenting: boolean) =>
	{
		postVuplexMessage(
			'VideoPlayer.OpenView',
			{
				url: props.signedURLs.itemURL,
				inventoryItemId: props.itemId,
				playing: !paused,
				paused: paused,
				looped: looped,
				time: currentTime,
				duration: duration,
				volume: volume ?? 1.0,
				muted: muted,
				playbackRate: 1.0,
				presenting: presenting
			}
		);

		closePreview();

		if (clientPlatform?.PlatformType !== "VR")
		{
			postVuplexMessage("Close tablet", null);
		}
	};

	const updateProgressBar = () =>
	{
		const update = setInterval(
			() => {
				if (videoRef.current == null)
					return;
				
				let curr = videoRef.current.currentTime;
				if (curr > currentTime)
					clearInterval(update);

				setCurrentTime(curr += 0.015);
			},
			15
		);
	};

	const closePreview = (e?: React.PointerEvent) => props.closePreview(e);

	updateProgressBar();

	const closeaudiomessage = "Menu/TeamFiles/VideoPlayer/Close/Press";
	const closehoveraudiomessage = "Menu/TeamFiles/VideoPlayer/Close/HL";

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.headerLeft}>
					<div>{props.noPresenting ? null : PresentButton}</div>
				</div>
				<div className={classes.headerCenter}>
					<h2 className={classes.title}>{props.itemInfo ? props.itemInfo.name : props.videoName ? props.videoName : ""}</h2>
				</div>
				<div className={classes.headerRight}>
					<OpenInNewButton onOpenInNew={() => importVideo(false)} />
					<CloseButton
						onClose={closePreview}
						uiAudioMessage={closeaudiomessage}
						uiHoverAudioMessage={closehoveraudiomessage}
					/>
				</div>
			</div>

			{Video}
			
			<VideoTimeline
				// id="videoProgressBar"
				time={currentTime}
				duration={duration}
				onSetTime={(t) => SeekTo(t)}
			/>

			<VideoControlFooter 
				// id="videoControls"
				muted={muted}
				volume={volume}
				paused={paused}
				loop={looped}
				onSetMuted={SetVolumeMuted}
				onSetVolume={SetVolume}
				onSetPaused={SetPaused}
				onSetLooped={SetLooped}
				onSkip={Skip}
			/>
			
			{buffering && (
				<div className={classes.loadingIndicatorWrapper}>
					<div className={classes.loadingIndicator}>
						<LoadingIndicator />
					</div>
				</div>
			)}
		</div>
	);
};

export default FileViewVideo;
