import React from 'react';
import { useNavigate } from 'react-router-dom';

import postVuplexMessage from '../service/message-vuplex';
import HeroIcon from './common/hero-icon';

import HelpIcon from '../icons/hero/Help.png';

const HelpHeroIcon = (props) =>
{
	const navigate = useNavigate();
	const audiomessage = "Tools/Help/Press"; 
	const hoveraudiomessage = "Tools/Help/HL";

	const onClickHelp = (toolbar) =>
	{
		if (toolbar)
		{
			postVuplexMessage('Request help view', null);
		}
		else
		{
			navigate('/view/help');
		}
	};

	return (
		<HeroIcon
			name="Help" 
			icon={HelpIcon}
			noBackground={props.noBackground}
			uiAudioMessage = {audiomessage}
			uiHoverAudioMessage = {hoveraudiomessage}
			onPointerDown={() => onClickHelp(props.isToolbar)}
			hotkey="3"
		/>
	);
};

export default HelpHeroIcon;
