import React, { useState } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core';

import queries from '../../graphql/queries';
import SwitchSelector from '../common/switch-selector';
import PageSelector from '../common/page-selector';
import { postVuplexMessage } from '../../service/message-vuplex';
import PdfViewer from './pdf-viewer';
import openDocumentView from './open-document-view';
import OpenInNewButton from '../common/open-in-new-button';
import CloseButton from '../common/close-button';
import { usePromptDialogContext } from '../../util/prompt-dialog-context';
import InfoDialogTemplate from '../common/info-dialog-template';

export const isViewable = (inventoryItemInfo) => {
	const isPdf = inventoryItemInfo.itemType === "application/pdf"
	return isPdf;
};

const useStyles = makeStyles((theme) => ({
	root: {
		flex: '1 0 auto',
		height: '928px',
		display: 'flex',
		flexFlow: 'column nowrap',
		justifyContent: 'center',
		alignItems: 'stretch',
		textAlign: 'center',
		position: 'relative',
		borderRadius: '25px',

		background: theme.palette.background.paper,
	},

	header: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		background: theme.palette.background.paper,
		height: theme.custom.header.height,
		minHeight: theme.custom.header.height,
		padding: theme.custom.header.padding,
		alignItems: 'center',
	},

	appContent: {
		height: '752px',
		overflow: 'hidden',
		display: 'flex',
		justifyContent: 'stretch',
		alignItems: 'stretch',
	},

	page: {
		width: '100%',
	},

	pageControls: {
		height: '88px',
		marginTop: '12px',

		display: 'flex',
		flexFlow: 'row nowrap',
		justifyContent: 'center',
	},

	headerLeft: {
		display: 'flex',
		gap: theme.glueSpacing('m'),
		marginLeft: theme.glueSpacing('l')
	},

	headerCenter: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	headerRight: {
		display: 'flex',
		gap: theme.glueSpacing('m')
	},

	title: {
		display: 'inline-block',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		textAlign: 'center',
	},
}));

const FileViewDocument = (props) => {
	const classes = useStyles();
	const apollo = useApolloClient();

	const { addDialog } = usePromptDialogContext();

	const [fileReady, setFileReady] = useState(false);

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(0);

	const clientPlatformResult = useQuery(queries.clientPlatform);
	const clientPlatform = clientPlatformResult.data?.clientPlatform;

	const onDocumentLoadSuccess = (numPages) => {
		setNumPages(numPages);
	};

	const onDocumentLoadError = (message) =>
	{
		addDialog(<InfoDialogTemplate
			isError={true}
			message={'ERROR!' + message}
		/>)
	}

	const closePreview = () => props.closePreview();

	const openView = (sharing) => {
		openDocumentView({
			apollo: apollo,
			inventoryItemId: props.itemInfo.inventoryItemId,
			itemUrl: props.itemInfo.itemUrl,
			itemType: props.itemInfo.itemType,
			sharing: sharing
		});

		closePreview();

		if (clientPlatform?.PlatformType !== "VR") {
			postVuplexMessage("Close tablet", null);
		}
	}

	const presentaudiomessage = "Menu/TeamFiles/PDFViewer/Present/Press";
	const presenthoveraudiomessage = "Menu/TeamFiles/PDFViewer/Present/HL";
	const closeaudiomessage = "Menu/TeamFiles/PDFViewer/Close/Press";
	const closehoveraudiomessage = "Menu/TeamFiles/PDFViewer/Close/HL";

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.headerLeft}>
					<SwitchSelector
							text="Present"
							disabled={!fileReady}
							onChange={() => openView(true)}
							uiAudioMessage={presentaudiomessage}
							uiHoverAudioMessage={presenthoveraudiomessage}
							checked={false}
					/>
				</div>
				<div className={classes.headerCenter}>
					<h2 className={classes.title}>{props.itemInfo.name}</h2>
				</div>
				<div className={classes.headerRight}>
					<OpenInNewButton onOpenInNew={() => openView(false)} />
					<CloseButton
						onClose={closePreview}
						uiAudioMessage={closeaudiomessage}
						uiHoverAudioMessage={closehoveraudiomessage}
					/>
				</div>
			</div>
			<div className={classes.appContent}>
				<PdfViewer
					url={new URL(props.signedURLs.itemURL)}
					onSuccess={onDocumentLoadSuccess}
					onError={onDocumentLoadError}
					pageNumber={pageNumber}
					pageClass={classes.page}
					onRender={() => setFileReady(true)}
				/>
			</div>
			<div className={classes.pageControls}>
				<PageSelector
					useInput
					size={"extraLarge"}
					current={pageNumber}
					pageCount={numPages}
					onPageChange={(pageNew) => setPageNumber(pageNew)}
				/>
			</div>
		</div>
	);
};

export default FileViewDocument;
