import React from 'react';
import HeroIcon from './common/hero-icon';

import Finger from '../icons/Gesture_ON.png';

const GesturesHeroIcon = (props) =>
{
	const clickSound = "Tools/Settings/Press";
	const hoveraudiomessage = "Tools/Settings/HL";

	return (
		<HeroIcon
			name="Gestures"
			noBackground={props.noBackground}
			icon={Finger}
			iconOff={Finger}
			uiAudioMessage={clickSound}
			uiHoverAudioMessage={hoveraudiomessage}
			onPointerDown={props.onPointerDown}
			hotkey="1"
		/>
	);
};

export default GesturesHeroIcon;